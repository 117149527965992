import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.admin.modal.server.add');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-admin-modal-server-add',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.admin.modal.server.add/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 500px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 500px;
}

.row * {
  line-height: 40px;
}
.row .th {
  width: 100px;
  font-family: "main-eb";
  text-align: left;
}
.row .col-md-4 .th {
  width: 50px;
}
.row .td {
  flex: 1;
  text-align: left;
}
.row .form-control {
  padding-top: 0;
  padding-bottom: 0;
}

textarea {
  resize: none;
}`],
})
export class PortalInfraAdminModalServerAddComponent implements OnInit {
    @Input() info = null;
    @Output() onClose = new EventEmitter();
    public mode = "new";

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        if (!this.info) {
            this.info = this.empty();
            this.mode = "new";
        }
        else {
            this.mode = "edit";
        }
        await this.loadUsers();
        await this.service.render();
    }

    public isNew() {
        return this.mode === "new";
    }

    public empty() {
        return {
            name: "",
            status: "run",
            owner_id: "",
            cpu: 4,
            ram: 8,
            disk: 100,
            ip: "",
            domain: "",
            esxi: "",
        };
    }

    public users = [];
    public async loadUsers() {
        const { code, data } = await wiz.call("users");
        if (code !== 200) return;
        this.users = data;
    }

    public async add() {
        const valid = await this.validation();
        if (!valid) return;
        const { code } = await wiz.call("add", this.info);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        // await this.service.success("서버 추가 성공");
        this.onClose.emit();
    }

    public async edit() {
        const valid = await this.validation();
        if (!valid) return;
        const { code } = await wiz.call("edit", this.info);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        // await this.service.success("서버 추가 성공");
        this.onClose.emit();
    }

    public async remove() {
        const res = await this.service.alert.show({
            title: "서버 삭제",
            status: "error",
            message: "이 서버를 삭제하시겠습니까?",
            action: "삭제",
            actionBtn: "error",
            cancel: "cancel",
        });
        if (!res) return;
        const body = {
            server_id: this.info.id,
        };
        const { code } = await wiz.call("remove", body);
        if (code !== 200) return await this.service.error(`ERROR CODE: ${code}`);
        await this.service.success("서버 삭제 성공");
        this.onClose.emit();
    }

    private async validation() {
        if (this.info.name.replace(/\s/g, "").length < 2) {
            await this.service.error("서버 이름은 2글자 이상 입력해주세요.");
            return false;
        }
        if (![2, 4, 8].includes(this.info.cpu * 1)) {
            await this.service.error("CPU는 2, 4, 8 중 하나로 입력해주세요.");
            return false;
        }
        if (this.info.ram < 4 || this.info.ram > 32) {
            await this.service.error("RAM은 4GB ~ 16GB 사이로 입력해주세요.");
            return false;
        }
        if (!Number.isInteger(this.info.disk)) {
            await this.service.error("Disk를 입력해주세요.");
            return false;
        }
        return true;
    }
}

export default PortalInfraAdminModalServerAddComponent;
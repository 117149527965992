import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.admin.modal.server.request');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-admin-modal-server-request',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.admin.modal.server.request/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 420px;
  max-width: 420px;
}

.row * {
  line-height: 40px;
}
.row .th {
  width: 100px;
  font-family: "main-eb";
  text-align: left;
}
.row .col-md-4 .th {
  width: 50px;
}
.row .td {
  flex: 1;
  text-align: left;
}
.row .form-control {
  padding-top: 0;
  padding-bottom: 0;
}

textarea {
  resize: none;
}`],
})
export class PortalInfraAdminModalServerRequestComponent implements OnInit {
    @Input() info: any;
    @Output() onClose = new EventEmitter();

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.data.ip = this.info.server.ip || "";
        this.data.domain = this.info.server.domain || "";
        this.data.esxi = this.info.server.esxi || "";
        this.data.response = this.info.response || "";
        await this.service.render();
    }

    public typeMap = {
        create: "신규 서버 생성",
        upgrade: "서버 용량 증설",
        delete: "서버 삭제 요청",
    };

    public modal = null;
    public data = {
        ip: "",
        domain: "",
        esxi: "",
        response: "",
    };

    public action() {
        if (this.modal === 'deny') return this.deny();
        else if (this.modal === 'accept') return this.accept();
    }

    public async deny() {
        const body = {
            id: this.info.id,
            response: this.data.response,
        };
        const { code } = await wiz.call("deny", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to deny");
        location.reload();
    }

    public async accept() {
        const body = {
            id: this.info.id,
            server_id: this.info.server.id,
            ...this.data,
        };
        const { code } = await wiz.call("accept", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to accept");
        location.reload();
    }
}

export default PortalInfraAdminModalServerRequestComponent;